<template>
  <div id="studentsNotes" class="text-sm relative" v-if="!loading">
    <div class="flex justify-between mb-6">
      <a-button class="mr-2" @click="$router.go(-1)">
        Regresar
      </a-button>
      <a-dropdown width="w-80">
        <template #menu>
          <!--          <a-dropdown-item @click="handleEvaluated">-->
          <a-dropdown-item @click="printDiv">
            imprimir
          </a-dropdown-item>
        </template>
      </a-dropdown>
    </div>
    <div class="flex justify-between mb-3 text-lg mx-2">
      <div>
        <p class="inline-block mr-2">Materia:</p>
        <p class="inline-block font-bold">{{ lessonValues.course_name }}</p>
      </div>
      <div>
        <p class="inline-block mr-2">Sección: </p>
        <p class="inline-block font-bold">{{ lessonValues.section_code }}</p>
      </div>
    </div>

    <div class="relative overflow-auto flex-grow">
      <div id="notes" class="border-b border-gray-200 absolute w-full" style="min-width: 1400px;">
        <div class="flex flex-col gap-2">
          <div class="flex">
            <div class="flex-none" id="students">
              <p class="text-center text-lg font-bold">Alumnos</p>
              <div style="height: 60px;" />
              <div class="flex text-center align-middle items-middle" v-for="(student, index) in students" :key="student.id" :class="index % 2 ? 'bg-white' : 'bg-gray-200'">
                <div class="flex-1 flex items-middle px-4">
                  <p
                      class="flex-1 py-1 text-xs"
                      :title="student.student_note.failed_by_final_delivery ? 'Reprobado por entrega final' : ''"
                      :class="student.student_note.failed_by_final_delivery ? 'text-red-400' : ''">
                    {{ student.student_name }}
                  </p>
                </div>
              </div>
            </div>

            <div class="flex-1" id="fc-notes">
              <p class="text-center text-lg font-bold">Primer corte</p>
              <div v-if="lessonValues.first_cut_evaluated_lessons_count === 0" style="height: 40px;"/>
              <div class="flex flex-col text-center">
                <div v-if="lessonValues.first_cut_evaluated_lessons_count > 0">
                  <div v-if="noteWeighs.first_cut_evaluations_date" class="flex text-center text-sm">
                    <p v-for="date in noteWeighs.first_cut_evaluations_date" :key="date.id" :title="`${date.split(' ')[1]}`" class="flex-1 text-sm">{{ date.split(' ')[0] }}</p>
                    <p class="flex-1"></p>
                  </div>
                  <div class="flex">
                    <p class="flex-1 text-center font-bold" v-for="value in noteWeighs.first_cut_evaluations" :key="value.id">{{ value + '%' }}</p>
                    <p class="flex-1" />
                  </div>
                  <div class="flex text-center font-bold bg-purple-400 text-white">
                    <p v-for="evaluated_lesson in lessonValues.first_cut_evaluated_lessons_count" :key="evaluated_lesson" class="flex-1" :title="`Nota ${evaluated_lesson}`">{{ `N${evaluated_lesson }` }}</p>
                    <p class="flex-1" title="Nota Acumulada">NA</p>
                  </div>
                  <div class="flex-1 flex flex-col items-middle text-center">
                    <div class="flex-1 flex" v-for="(notes, index) in fc_notes" :key="notes.id" :class="index % 2 ? 'bg-white' : 'bg-gray-200'">
                      <p class="flex-1 my-1 text-xs" v-for="studentNote in notes.notes_values" :key="studentNote.id" :class="studentNote.note || studentNote.note === 0 ? '' : 'text-red-400'">{{ studentNote.note || studentNote.note === 0 ? studentNote.note + '/' + parseFloat(studentNote.total_note).toFixed(2) : 'SN' }}</p>
                      <!--                    <p class="flex-1 my-1 text-xs">{{ notes.first_cut_final_note }}</p>-->
                      <p class="flex-1 my-1 text-xs">{{ parseFloat(notes.first_cut_accumulative_total).toFixed(2) }}</p>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center font-bold bg-purple-400 text-white">
                  <p>Sin notas aún</p>
                </div>
              </div>
            </div>

            <div class="flex-1" id="sc-notes" v-if="lessonValues.second_cut_evaluated_lessons_count > 0">
              <p class="text-center text-lg font-bold">Segundo corte</p>
              <div v-if="lessonValues.second_cut_evaluated_lessons_count === 0" style="height: 40px;"/>
              <div class="flex flex-col text-center" >
                <div v-if="lessonValues.second_cut_evaluated_lessons_count > 0">
                  <!-- Date -->
                  <div v-if="noteWeighs.second_cut_evaluations_date" class="flex text-center text-sm">
                    <p v-for="date in noteWeighs.second_cut_evaluations_date" :key="date.id" :title="`${date.split(' ')[1]}`" class="flex-1 text-sm">{{ date.split(' ')[0] }}</p>
                    <p class="flex-1"></p>
                  </div>
                  <!-- Percents -->
                  <div class="flex">
                    <p class="flex-1 text-center font-bold" v-for="value in noteWeighs.second_cut_evaluations" :key="value.id">{{ value + '%' }}</p>
                    <p class="flex-1" />
                  </div>
                  <!-- Notes label -->
                  <div class="flex text-center font-bold bg-purple-400 text-white">
                    <p v-for="evaluated_lesson in lessonValues.second_cut_evaluated_lessons_count" :key="evaluated_lesson" class="flex-1" :title="`Nota ${evaluated_lesson}`">{{ `N${evaluated_lesson }` }}</p>
                    <p class="flex-1" title="Nota Acumulada">NA</p>
                  </div>
                  <!-- Notes -->
                  <div class="flex-1 flex flex-col items-middle text-center">
                    <div class="flex-1 flex" v-for="(notes, index) in sc_notes" :key="notes.id" :class="index % 2 ? 'bg-white' : 'bg-gray-200'">
                      <p class="flex-1 my-1 text-xs" v-for="studentNote in notes.notes_values" :key="studentNote.id" :class="studentNote.note || studentNote.note === 0 ? '' : 'text-red-400'">{{ studentNote.note || studentNote.note === 0 ?  studentNote.note + ' / ' + parseFloat(studentNote.total_note).toFixed(2) : 'SN' }}</p>
<!--                      <p class="flex-1 my-1 text-xs" v-for="studentNote in notes.notes_values" :key="studentNote.id" :class="studentNote.note ? '' : 'text-red-400'">{{ studentNote.note ?  studentNote.note + ' / ' + getGeneralNote(studentNote.note, studentNote.evaluation_weighting_prc ).toFixed(2) : 'SN' }}</p>-->
                      <!--                    <p class="flex-1 my-1 text-xs">{{ notes.second_cut_final_note }}</p>-->
                      <p class="flex-1 my-1 text-xs">{{ parseFloat(notes.second_cut_accumulative_total).toFixed(2) }}</p>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center font-bold bg-purple-400 text-white">
                  <p>Sin notas aún</p>
                </div>
              </div>
            </div>

            <div class="flex-none" id="f-notes" style="min-width: 150px;">
              <p class="text-center text-lg font-bold">Notas finales</p>
              <div style="height: 40px;"/>
              <div class="flex flex-col text-center" >
                <!--                <div class="flex">-->
                <!--                  <p class="flex-1 text-center font-bold" v-for="note in sc_notes" :key="note">{{ note.evaluation_weighting_prc + '%' }}</p>-->
                <!--                  <p class="flex-1" />-->
                <!--                </div>-->
                <div>
                  <div class="flex text-center font-bold bg-purple-400 text-white">
                    <!--                  <p v-for="evaluated_lesson in lessonValues" :key="evaluated_lesson" class="flex-1" :title="`Nota ${evaluated_lesson}`">{{ `N${evaluated_lesson }` }}</p>-->
                    <p v-if="noteWeighs.final_delivery_prc" class="flex-1" title="Nota de Entrega Final">EF</p>
                    <p class="flex-1" title="Nota Promedio" v-if="lessonValues.second_cut_evaluated_lessons_count > 0">NP</p>
                    <p class="flex-1" title="Nota Final">NF</p>
                  </div>
                  <div class="flex-1 flex flex-col items-middle text-center border-l-2 border-purple-400">
                    <div class="flex-1 flex items-center" v-for="(notes, index) in students" :key="notes.id" :class="index % 2 ? 'bg-white' : 'bg-gray-200'">
                      <p v-if="noteWeighs.final_delivery_prc" class="flex-1 my-1 px-2 text-xs">{{ notes.student_note.final_delivery_note }}</p>
                      <p class="flex-1 my-1 px-2 text-xs" v-if="lessonValues.second_cut_evaluated_lessons_count > 0">{{ parseFloat(notes.student_note.final_note_raw).toFixed(2) }}</p>
<!--                      <p class="flex-1 my-1 px-2 text-xs">{{ notes.student_note.final_note ? notes.student_note.final_note : '-' }}</p>-->
                      <p class="flex-1 my-1 px-2 text-xs">{{ notes.student_note.overwritten_final_note || notes.student_note.overwritten_final_note === 0 ? notes.student_note.overwritten_final_note : notes.student_note.final_note }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import StudentNotesCard from './studentsNotesCard'
import { mapState, mapActions } from 'vuex'
import moment from '@/filters/moment'

export default {
  name: 'studentsNotes',
  // components: {
  //   StudentNotesCard
  // }
  data () {
    return {
      lessonValues: [],
      students: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      period: state => state.periods.paginantion
    }),
    fc_notes () {
      return this.students?.map($0 => {
        return JSON.parse(JSON.stringify({ notes_values: $0.first_cut, first_cut_final_note: $0.student_note.first_cut_note, first_cut_accumulative_total: $0.first_cut_accumulative_total }))
      })
    },
    sc_notes () {
      return this.students?.map($0 => {
        return JSON.parse(JSON.stringify({ notes_values: $0.second_cut, second_cut_final_note: $0.student_note.second_cut_note, second_cut_accumulative_total: $0.second_cut_accumulative_total }))
      })
    },
    noteWeighs () {
      const fc_evaluations = this.students[0]?.first_cut.map($0 => $0.evaluation_weighting_prc)
      const fc_evaluations_date = this.students[0]?.first_cut.map($0 => moment($0.date, 'DD/MM YYYY'))//.sort((a, b) => moment(a.date).diff(moment(b.date)))
      const sc_evaluations = this.students[0]?.second_cut.map($0 => $0.evaluation_weighting_prc)
      const sc_evaluations_date = this.students[0]?.second_cut.map($0 => moment($0.date, 'DD/MM YYYY'))//.sort((a, b) => moment(a.date).diff(moment(b.date)))
      const final_note_evaluation = this.students[0]?.student_note.final_delivery_note_weighting_prc

      return {
        first_cut_evaluations: fc_evaluations,
        first_cut_evaluations_date: fc_evaluations_date,
        first_cut_note_weighting_prc: this.students[0]?.student_note.first_cut_note_weighting_prc,
        second_cut_evaluations: sc_evaluations,
        second_cut_evaluations_date: sc_evaluations_date,
        second_cut_note_weighting_prc: this.students[0]?.student_note.second_cut_note_weighting_prc,
        final_delivery_prc: final_note_evaluation
      }
    },
  },
  // TODO EL PORCENTAJE TOTAL DE LAS NOTAS PUEDE VARIAR ( UN SEMESTRE PUEDE SER BASE 10, 20 O LO QUE SEA)
  methods: {
    ...mapActions({
      fetchPeriods: 'periods/index'
    }),
    moment: () => moment(),
    getPercentNote(note, evaluationPrc) {
      return Math.floor((note * evaluationPrc)*100) / 20
    },
    getGeneralNote(note, evaluationPrc) {
      return (this.getPercentNote(note, evaluationPrc) * 20) / 100.0
    },
    getAccumulatedNote(notes) {
      let accumulatedNote = 0
      notes.forEach((it) => {
        accumulatedNote += this.getGeneralNote(it.note, it.evaluation_weighting_prc)
      })
      // return accumulatedNote.toFixed(2)
      return Math.floor(accumulatedNote)/100.0
    },
    printDiv() {
      // let printContents = document.getElementById('schedule').innerHTML;
      let win = window.open('', 'PRINT', 'height=768,width=1366');

      win.document.write('<html><head><title>' + document.title  + '</title>');
      win.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.8.10/tailwind.min.css"/>');
      win.document.write('</head><style>body {-webkit-print-color-adjust: exact;padding-top: 15px;font-size: 12px;} br{height: 20px;} #notes{ tranform: scale(-1)}</style><body >');
      // win.document.write('<h1>' + document.title  + '</h1>');
      win.document.write(document.querySelector('#notes').innerHTML);
      win.document.write('</body></html>');
      // win.document.write(document.querySelector('#notes').innerHTML);
      // win.document.write('<div class="border-b border-gray-200 absolute w-full" style="min-width: 1400px;">\n' +
      //     '<div class="flex flex-col gap-2">\n' +
      //     '<div class="flex">')
      // win.document.write(document.querySelector('#students').innerHTML);
      // win.document.write(document.querySelector('#fc-notes').innerHTML);
      // win.document.write('</div></div></div>')
      // win.document.write('<br>')
      // win.document.write('<br>')
      // win.document.write('<div class="border-b border-gray-200 absolute w-full" style="min-width: 1400px;">\n' +
      //     '<div class="flex flex-col gap-2">\n' +
      //     '<div class="flex">')
      // win.document.write(document.querySelector('#students').innerHTML);
      // win.document.write(document.querySelector('#sc-notes').innerHTML);
      // win.document.write(document.querySelector('#f-notes').innerHTML);
      // win.document.write('</div></div></div>')
      //
      // win.document.write('</body></html>');

      win.document.close(); // necessary for IE >= 10
      win.focus(); // necessary for IE >= 10*/

      setTimeout(function () {
        win.print();
      }, 3000)
    },
  },
  mounted () {
    this.loading = true

    this.$repository.sections
      .studentsNotes(this.$route.params.section_id, this.$route.params.course_id)
      .then(({ data:response }) => {
        this.lessonValues = response

        function compare(a, b) {
          if (a.student_name < b.student_name) {
            return -1;
          }
          if (a.student_name > b.student_name) {
            return 1;
          }
          // a debe ser igual b
          return 0;
        }

        function compareDate(a, b) {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          // a debe ser igual b
          return 0;
        }

        this.students = response.students.sort(compare).map(($0) => {
          $0.first_cut = $0.first_cut.sort(compareDate)
          $0.second_cut = $0.second_cut.sort(compareDate)

          return $0
        })


      })
      .finally(() => this.loading = false)
      //console.log("noteWeighs",this.noteWeighs && this.noteWeighs.second_cut_evaluations_date)
  },
}
</script>

<style scoped>
br {
  height:50px !important;
}
</style>
